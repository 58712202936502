<swe-pageheader [header]="languageService.getItem(4)"><span swe-hits>&nbsp;({{hits}}<span *ngIf="more">+</span>)</span></swe-pageheader>
<swe-fixedheader [listutility]="listutility" [datePagerOpt]="{type: 'booking', hideTime: true}" (searchEvent)="handleSearchEvent($event)" [listoptions]="listoptions" [more]="more" >
  <ng-template #listoptions>
      <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe" (click)="actionmenu=!actionmenu" [attr.disabled]="listutility.ischecked ? null : true">
        <span><i class="bi bi-swe-fw bi-three-dots-vertical"></i></span>
      </button>
      <ul class="dropdown-menu" [ngClass]="{'show': actionmenu && listutility.ischecked}">
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.CopyShift>0"><a (click)="action('copy')"><i class="bi bi-swe-fw bi-journals"></i>&nbsp;{{languageService.getItem(354)}}</a></li>
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.MultiShift>0"><a (click)="action('multi')"><i class="bi bi-swe-fw bi-copy"></i>&nbsp;{{languageService.getItem(410)}}</a></li>
        <li class="dropdown-divider" *ngIf="(permissionService.permissions.CopyShift>0||permissionService.permissions.MultiShift>0)&&(permissionService.permissions.ListSms>0||permissionService.permissions.BookingListSms>0)"></li>
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.BookingListSms>0">
          <swe-confirm [isbutton]="false" [body]="languageService.getItem(927)" (acceptChange)="send(3, 0, $event)" (declineChange)="actionmenu=false">
            <i class="bi bi-swe-fw bi-phone-fill"></i>&nbsp;{{languageService.getItem(320)}}
          </swe-confirm>
        </li>
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.ListSms>0">
          <swe-confirm [isbutton]="false" [body]="languageService.getItem(928)" (acceptChange)="send(6, 0, $event)" (declineChange)="actionmenu=false">
            <i class="bi bi-swe-fw bi-phone-fill"></i>&nbsp;{{languageService.getItem(178)}}
          </swe-confirm>
        </li>
      </ul>
  </ng-template>
</swe-fixedheader>
<div class="row py-2">
      <swe-infotext class="mb-2" [text]="languageService.getItem(535)"></swe-infotext>
  <div class="col-12">
    <swe-card [hasCollapse]="false" (refreshChange)="search(false)" [rulelist]="['SweLevels', 'SweRoles', 'SweHasUserBookingProfile', 'SweHasBookingListSms']">
      <span swe-rightcommand *ngIf="permissionService.permissions.Bookings>1">
        <i class="bi bi-swe-fw bi-plus-lg bi-swe-pull-right swe-click" (click)="open(0, $event)"></i>
      </span>
      <swe-list [data]="bookings" [loading]="loading">
        <div *ngFor="let groupby of groupedby | keyvalue">
          <!--Header-->
          <a class="list-group-item list-group-item-action d-flex flex-wrap swe-bg-light">
            <h2>{{groupby.value.header}}<span class="text-danger" *ngIf="groupby.value.today">&nbsp;({{languageService.getItem(76)}})</span></h2>
          </a>
          <!--Booking-->
          <a href="javascript:void(0);" class="list-group-item list-group-item-action d-flex swe-click" [ngClass]="{'list-group-item-warning swe-scroll-to': booking.Id==settingService.lastbooking&&!booking.checked,'list-group-item-info': booking.checked}" *ngFor="let booking of groupby.value.items" (click)="!listutility.ischecked&&open(booking.Id, $event)">
            <div class="col-2 col-md-1" (click)="listutility.toggle(booking, $event)" (contextmenu)="showdetail(booking, $event)">
              <i class="bi bi-swe bi-check-circle-fill" *ngIf="booking.checked"></i>
              <i class="bi bi-swe-border swe-icon-color" *ngIf="!booking.checked&&booking.Status>10" [attr.title]="booking.StatusText" [ngClass]="{'bi-arrow-up-right-square': booking.Status==20,'bi-key-fill': booking.Status==30,'bi-clock-fill': booking.Status==40,'bi-check-lg': booking.Status==50,'bi-lock-fill': booking.Status==60,'alert-danger border-danger': booking.Amount==0&&(booking.Max>0||!permissionService.permissions.ZeroAsFull),'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}"></i>
              <i class="bi bi-swe-border bi-square swe-icon-color" *ngIf="!booking.checked&&booking.Status==10" [ngClass]="{'alert-danger border-danger': booking.Amount==0&&(booking.Max>0||!permissionService.permissions.ZeroAsFull),'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}" [attr.title]="booking.StatusText"></i>
              <i class="bi bi-swe-border bi-link-45deg" *ngIf="!booking.checked&&booking.Serie>0" [attr.title]="languageService.getItem(203)"></i>
              <i class="bi bi-swe-border bi-list-ul" *ngIf="!booking.checked&&booking.HasActivities>0" [attr.title]="languageService.getItem(741)"></i>
              <i class="bi bi-swe-border bi-shield-shaded" *ngIf="!booking.checked&&booking.EmploymentPlan" [attr.title]="languageService.getItem(698)"></i>
              <i class="bi bi-swe-border bi-chat" *ngIf="!booking.checked&&booking.Sms" [attr.title]="languageService.getItem(205)"></i>
              <i class="bi bi-swe-border bi-send" *ngIf="!booking.checked&&booking.Request" [attr.title]="languageService.getItem(1078)"></i>
              <i class="bi bi-swe-border bi-eye-fill" *ngIf="!booking.checked&&booking.IsWatching" [attr.title]="languageService.getItem(339)"></i>
              <i class="bi bi-swe-border bi-swe-bookingtype-replaceable" *ngIf="!booking.checked&&booking.HasReplaceable" [attr.title]="languageService.getItem(423)"></i>
              <i class="bi bi-swe-border bi-swe-bookingtype-standby" *ngIf="!booking.checked&&booking.HasStandby" [attr.title]="languageService.getItem(279)"></i>
              <i class="bi bi-swe-border bi-swe-bookingtype-abscent" *ngIf="!booking.checked&&booking.HasAbscent" [attr.title]="languageService.getItem(280)"></i>
              <i class="bi bi-swe-border bi-swe-bookingtype-decline" *ngIf="!booking.checked&&booking.HasDecline" [attr.title]="languageService.getItem(1106)"></i>
              <!--Detail-->
              <div class="swe-detail-block"
                   [ngClass]="{'swe-reference-block': detail.booking.Type>1, 'alert-danger border-danger': detail.booking.Amount==0&&(detail.booking.Max>0||!permissionService.permissions.ZeroAsFull),'alert-warning border-warning': (detail.booking.Amount>0&&detail.booking.Amount<detail.booking.Max), 'alert-success border-success': (detail.booking.Amount==detail.booking.Max)}"
                   [ngStyle]="{'left': detail.left + '%','top': detail.top + 'px','height': detail.height + 'px', 'min-width': detail.width + 'px'}"
                   (contextmenu)="resetdetail($event)"
                   *ngIf="detail.row == booking.Id">
                <div style="position:relative;width:100%;height:100%;overflow:hidden;">
                  <!--Detail Header-->
                  <div class="swe-detail-header">
                    <div>
                      <i class="bi ps-1" *ngIf="detail.booking.Status>10" [ngClass]="{'bi-arrow-up-right-square': detail.booking.Status==20,'bi-key-fill': detail.booking.Status==30,'bi-clock-fill': detail.booking.Status==40,'bi-check-lg': detail.booking.Status==50,'bi-lock-fill': detail.booking.Status==60}"></i>
                      <i class="bi bi-square ps-1" *ngIf="detail.booking.Status==10"></i>
                      <i class="bi bi-link-45deg ps-1" *ngIf="detail.booking.Serie>0"></i>
                      <i class="bi bi-shield-shaded ps-1" *ngIf="detail.booking.EmploymentPlan"></i>
                      <i class="bi bi-chat ps-1" *ngIf="detail.booking.Sms"></i>
                      <i class="bi bi-send ps-1" *ngIf="detail.booking.Request"></i>
                      <i class="bi bi-swe-bookingtype-replaceable ps-1" *ngIf="detail.booking.Type==0||detail.booking.HasReplaceable"></i>
                      <i class="bi bi-swe-bookingtype-standby ps-1" *ngIf="detail.booking.Type==2||detail.booking.HasStandby"></i>
                      <i class="bi bi-swe-bookingtype-abscent ps-1" *ngIf="detail.booking.Type==3||detail.booking.HasAbscent"></i>
                      <i class="bi bi-swe-bookingtype-decline ps-1" *ngIf="detail.booking.Type==4||detail.booking.HasDecline"></i>
                      <i class="bi bi-box-arrow-in-right ps-1" *ngIf="detail.booking.HasCheckIn"></i>
                      {{detail.booking.dateheader}}
                      <i class="bi bi-swe-fw bi-x-lg bi-swe-pull-right swe-click" (click)="resetdetail($event)"></i>
                    </div>
                    <div *ngIf="!permissionService.permissions.HideLevels">{{detail.booking.Level}}</div>
                    <div>{{detail.booking.Header}}</div>
                    <div>
                      <span *ngIf="!permissionService.permissions.HideAmount">{{detail.booking.Amount}}({{detail.booking.Max}})</span>
                      <span *ngIf="!permissionService.permissions.HideAmount&&detail.booking.Serie>0">, </span>
                      <span *ngIf="detail.booking.Serie>0">{{languageService.getItem(250)}}: {{detail.booking.Serie}}</span>
                    </div>
                  </div>
                  <!--Detail Content-->
                  <div class="table-responsive">
                    <table class="swe-table">
                      <tr>
                        <td>
                          <div class="swe-table-block" *ngIf="detailprofileready">
                            <table class="swe-table">
                              <tr>
                                <th colspan="2">{{languageService.getItem(29)}}</th>
                              </tr>
                              <tr *ngFor="let detailprofile of detail.profile">
                                <td>{{detailprofile.Name}}</td>
                                <td [ngSwitch]="detailprofile.Type">
                                  <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !detailprofile.Value,'bi-check-square': detailprofile.Value}" *ngSwitchCase="'System.Boolean'"></i>
                                  <span *ngSwitchCase="'Internetplatsen.Document'">{{detailprofile.Value.split('|')[0]}}</span>
                                  <span *ngSwitchCase="'System.List'">{{detailprofile.Value}}</span>
                                  <span *ngSwitchCase="'System.MultiList'">{{detailprofile.Value}}</span>
                                  <ng-container *ngSwitchCase="'System.DateTime'">
                                    <span *ngIf="detailprofile.Value!=null">{{detailprofile.Value}}</span>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'System.Date'">
                                    <span *ngIf="detailprofile.Value!=null">{{detailprofile.Value}}</span>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'System.Time'">
                                    <span *ngIf="detailprofile.Value!=null">{{detailprofile.Value}}</span>
                                  </ng-container>
                                  <span *ngSwitchDefault>{{detailprofile.Value}}</span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </td>
                        <td class="align-top">
                          <div class="swe-table-block" *ngFor="let user of detail.users | keyvalue">
                            <table class="swe-table">
                              <tr>
                                <th colspan="2">{{user.value.header}}</th>
                              </tr>
                              <tr *ngFor="let detailuser of user.value.items">
                                <td>{{detailuser.Firstname}}</td>
                                <td>{{detailuser.Lastname}}</td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-10 col-md-11 d-flex flex-wrap">
              <div class="col-7 col-md-2">{{booking.dateheader}}</div>
              <div class="col-5 col-md-1">{{booking.Amount}} ({{booking.Max}})</div>
              <div class="col-12 col-md-3">{{booking.Header}}</div>
              <div class="col-12 col-md-2">{{booking.Desc}}<span *ngIf="booking.Serie>0"> {{languageService.getItem(250)}}: {{booking.Serie}}</span></div>
              <div class="col-12 col-md-4" *ngIf="!permissionService.permissions.HideLevels">{{booking.Level}}</div>
            </div>
          </a>
        </div>
        <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
      </swe-list>
    </swe-card>
  </div>
</div>
