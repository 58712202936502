<swe-pageheader [header]="languageService.getItem(1009)">
  <span swe-hits>
    &nbsp;(#{{id}})
  </span>
</swe-pageheader>
<div class="row py-2">
  <div class="col-12">
    <swe-card [open]="true" [hasCollapse]="false" [hasRefresh]="false" *ngIf="permissionService.permissions.Templates>0">
      <swe-element [type]="'System.Text'" [label]="languageService.getItem(509)" [statusLabel]="2" [(model)]="template.Name"></swe-element>
      <div class="mb-3">
        <swe-element [bottomMargin]="0" [type]="'System.LevelSearch'" [label]="languageService.getItem(14)" [statusLabel]="2" [(model)]="template.LevelId" [display]="template.LevelName" [optional]="true"></swe-element>
        <span class="small" *ngIf="template.LevelPath&&template.LevelPath.length>0"><i>({{template.LevelPath}})</i></span>
      </div>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(1081)" [statusLabel]="2" [(model)]="template.Type" [items]="types"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(1423)" [statusLabel]="2" [(model)]="template.WeekOne" [items]="weekOneTypes"></swe-element>
      <swe-card [header]="languageService.getItem(492)" [color]="'swe'" [open]="false" [hasCollapse]="true" [hasRefresh]="false">
        <swe-element [type]="'System.Date'" [label]="languageService.getItem(396)" [statusLabel]="2" [(model)]="template.Start"></swe-element>
        <swe-element [type]="'System.Date'" [label]="languageService.getItem(397)" [statusLabel]="2" [(model)]="template.End"></swe-element>
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(988)" [statusLabel]="2" [(model)]="template.ShowOverview"></swe-element>
        <swe-element *ngIf="permissionService.permissions.EmploymentPlan > 0" [type]="'System.Boolean'" [disabled]="permissionService.permissions.EmploymentPlan < 2" [label]="languageService.getItem(698)" [statusLabel]="2" [(model)]="template.EmploymentPlan"></swe-element>
        <swe-element [type]="'System.List'" [label]="languageService.getItem(1378)" [statusLabel]="2" [(model)]="template.ParentId" [items]="parents" [optional]="true" [optionalValue]="0"></swe-element>
        <swe-card [header]="languageService.getItem(1373)" [color]="'swe'" [open]="true" [hasCollapse]="false" [hasRefresh]="false" [hasSave]="true" (saveChange)="save()">
          <div class="row py-2">
            <div class="col-12 col-md-6">
              <swe-element [type]="'System.List'" [label]="languageService.getItem(1374)" [statusLabel]="2" [(model)]="template.CalcDayStart" [items]="calcweekdays" [optional]="true" [optionalValue]="-1"></swe-element>
              <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1376)" [statusLabel]="2" [(model)]="template.CalcNoDays"></swe-element>
              <swe-element [type]="'System.List'" [label]="languageService.getItem(1377)" [statusLabel]="2" [(model)]="template.MidnightOption" [items]="midnightoptions" [optional]="true" [optionalValue]="0"></swe-element>
              <swe-element [type]="'System.Double'" [label]="languageService.getItem(1386)" [statusLabel]="2" [(model)]="template.FullTime"></swe-element>
              <swe-element [type]="'System.Double'" [label]="languageService.getItem(1387)" [statusLabel]="2" [(model)]="template.Admin"></swe-element>
            </div>
            <div class="col-12 col-md-4">
              <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1382)" [statusLabel]="2" [(model)]="template.NumberOfShifts" [disabled]="true"></swe-element>
              <swe-element [type]="'System.Double'" [label]="languageService.getItem(1286)" [statusLabel]="2" [model]="template.NumberOfHours | number: '.0-2'" [disabled]="true"></swe-element>
              <div class="float-end">
                <a href="javascript:void(0)" class="swe-no-link me-1" (click)="prevYear()"><i class="bi bi-swe-fw bi-dash-circle"></i></a>
                <span>{{languageService.getItem(694)}} {{calculationYear}}</span>
                <a href="javascript:void(0)" class="swe-no-link ms-1" (click)="nextYear()"><i class="bi bi-swe-fw bi-plus-circle"></i></a>
              </div>
              <swe-element [type]="'System.Double'" [label]="languageService.getItem(1422)" [statusLabel]="2" [(model)]="calculationYearHours" [disabled]="true"></swe-element>
              <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1383)" [statusLabel]="2" [(model)]="template.OffDuty" [disabled]="true"></swe-element>
              <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1384)" [statusLabel]="2" [(model)]="template.Vacation" [disabled]="true"></swe-element>
              <swe-element [type]="'System.Double'" [label]="languageService.getItem(1388)" [statusLabel]="2" [(model)]="template.EmploymentRate" [disabled]="true"></swe-element>
            </div>
            <div class="col-12 col-md-2">
              <swe-element [type]="'System.Double'" [label]="getTimetypeActivityLabel(item)" [statusLabel]="2" [model]="item.Minutes/60 | number: '.0-2'" [disabled]="true" *ngFor="let item of template.TimeTypeFactors"></swe-element>
            </div>
          </div>
        </swe-card>
      </swe-card>
      <swe-card [header]="languageService.getItem(6)" [hasRefresh]="false" [open]="true" *ngIf="template && template.Settings">
        <div class="table-responsive mt-3">
          <table class="table table-sm table-bordered">
            <thead>
              <tr>
                <th>{{languageService.getItem(480)}}</th>
                <th>{{languageService.getItem(14)}}</th>
                <th>{{languageService.getItem(15)}}</th>
                <th>{{languageService.getItem(899)}}</th>
                <th *ngIf="permissionService.permissions.HasBreaks">{{languageService.getItem(785)}}</th>
                <th>{{languageService.getItem(1012)}}</th>
                <th>{{languageService.getItem(1013)}}</th>
                <th>{{languageService.getItem(355)}}</th>
                <th>{{languageService.getItem(1014)}}</th>
                <th>{{languageService.getItem(1015)}}</th>
                <th>{{languageService.getItem(1016)}}</th>
                <th>{{languageService.getItem(1017)}}</th>
                <th>{{languageService.getItem(395)}}</th>
                <th>{{languageService.getItem(156)}}</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let setting of template.Settings; index as i;">
                <tr [ngClass]="{'bg-danger text-light': setting.IsDeleted && !setting.IsEdit, 'bg-warning': setting.IsError && !setting.IsEdit,'bg-success text-light': setting.IsNew && !setting.IsEdit, 'swe-disabled-block': !setting.IsActive, 'bg-info': setting.IsEdit}">
                  <td style="width:150px;" class="pt-2"><span class="mt-3">{{setting.Name}}</span></td>
                  <td class="pt-2">
                    <span>{{setting.CurrentLevelName}} <span class="small" *ngIf="setting.CurrentLevelPath&&setting.CurrentLevelPath.length>0"><i>({{setting.CurrentLevelPath}})</i></span> </span>
                  </td>
                  <td class="pt-2"><span class="mt-3">{{setting.StatusName}}</span></td>
                  <td class="pt-2"><span style="white-space:nowrap;">{{getTimeSpan(setting.Start, setting.End)}}</span></td>
                  <td class="pt-2" *ngIf="permissionService.permissions.HasBreaks">
                    <span *ngIf="setting.MealBreak">{{languageService.getItem(1415)}}</span>
                    <ng-container *ngIf="!setting.MealBreak">
                      <div *ngFor="let break of setting.Breaks;">
                        <span class="text-nowrap" style="white-space:nowrap;" *ngIf="break.Break > 0">{{break.Break}} {{languageService.getItem(786).substring(0,3)}} {{getTime(break.BreakStart)}}</span>
                      </div>
                    </ng-container>
                  </td>
                  <td><swe-element [type]="'System.MultiList'" [label]="languageService.getItem(1012)" [statusLabel]="0" [(model)]="setting.Weekdays" [items]="weekdays" [container]="{isbit:true}" [access]="1"></swe-element></td>
                  <td class="pt-2"><span>{{setting.WeekNr}}</span></td>
                  <td class="pt-2"><span>{{setting.IterationName}}</span></td>
                  <td class="pt-2"><span>{{setting.Quantity}}</span></td>
                  <td class="pt-2"><span>{{setting.Min}}</span></td>
                  <td class="pt-2"><span> {{setting.Max}}</span></td>
                  <td><swe-element [type]="'System.List'" [label]="languageService.getItem(1017)" [statusLabel]="0" [model]="setting.TimeType" [items]="timetypes" [optional]="true" [access]="1"></swe-element></td>
                  <td class="pt-2">
                    <div *ngFor="let activity of setting.Activities" class="px-2" [ngClass]="{'bg-danger text-light': activity.IsDeleted}">
                      <span *ngIf="activity.Start && activity.End" style="white-space:nowrap;">{{getActivityName(activity.ActivityType)}} {{getTimeSpan(activity.Start, activity.End)}}</span>
                      <span *ngIf="!activity.Start || !activity.End" style="white-space:nowrap;">{{getActivityName(activity.ActivityType)}}</span>
                    </div>
                  </td>
                  <td>
                    <div *ngFor="let bookingprofile of setting.Properties" class="flex-row d-flex px-2" [ngClass]="{'bg-danger text-light': bookingprofile.IsDeleted}">
                      <swe-element [type]="'System.List'" [label]="languageService.getItem(1020)" [statusLabel]="0" [model]="bookingprofile.PropertyId" [items]="properties" [access]="1"></swe-element>
                      <swe-element [model]="bookingprofile.Value" [label]="languageService.getItem(1021)" [type]="bookingprofile.Type" [items]="manageReload(bookingprofile)" [access]="1"></swe-element>
                    </div>
                  </td>
                  <td>
                    <a href="javascript:void(0)" class="swe-no-link me-2" (click)="editSetting(setting,i)" [attr.title]="!setting.IsEdit ? languageService.getItem(1299):languageService.getItem(1301)"><i class="bi bi-swe-fw bi-swe bi-swe" [ngClass]="{'bi-pencil-square': !setting.IsEdit, 'bi-x-lg': setting.IsEdit}"></i></a>
                    <a href="javascript:void(0)" class="swe-no-link me-2" (click)="addSetting(setting)" [attr.title]="languageService.getItem(1298)" *ngIf="!setting.IsEdit"><i class="bi bi-swe-fw bi-swe bi-copy"></i></a>
                    <a href="javascript:void(0)" class="swe-no-link" (click)="deleteSetting(setting)" [attr.title]="languageService.getItem(1300)" *ngIf="!setting.IsEdit"><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-trash-fill': !setting.IsDeleted, 'bi-arrow-counterclockwise': setting.IsDeleted}"></i></a>
                  </td>
                </tr>
              </ng-container>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="15"><a href="javascript:void(0)" class="swe-no-link" (click)="addSetting()"><i class="bi bi-swe-fw bi-swe bi-plus-square-fill"></i>{{languageService.getItem(100)}}</a></td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="text-md-center mb-4" *ngIf="selectedSetting">
          <div class="btn-group">
            <button class="btn btn-swe" [disabled]="selectedIndex <=0" (click)="page($event, -1)"><i class="bi bi-swe-fw bi-swe bi-arrow-left"></i></button>
            <span class="my-2 mx-3">{{selectedIndex}}</span>
            <button class="btn btn-swe" [disabled]="selectedIndex >= template.Settings.length -1" (click)="page($event, 1)"><i class="bi bi-swe bi-swe-fw bi-arrow-right"></i></button>
            <button class="btn btn-swe btn-primary ms-3" *ngIf="selectedSetting" (click)="closeEdit($event)"><i class="bi bi-swe-fw bi-swe bi-x-lg"></i></button>
            <button class="btn btn-swe btn-primary" *ngIf="selectedSetting" (click)="addSetting(selectedSetting)"><i class="bi bi-swe-fw bi-swe bi-copy"></i></button>
            <button class="btn btn-swe btn-primary" *ngIf="selectedSetting" (click)="deleteSetting(selectedSetting)"><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-trash-fill': !selectedSetting.IsDeleted, 'bi-arrow-counterclockwise': selectedSetting.IsDeleted}"></i></button>
          </div>
        </div>
        <swe-templatesettingdata [setting]="selectedSetting" [properties]="properties" *ngIf="selectedSetting"></swe-templatesettingdata>
      </swe-card>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="save()" *ngIf="(permissionService.permissions.Templates>1)"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <button class="btn btn-primary" (click)="copy()" *ngIf="id>0&&(permissionService.permissions.Templates>1)"><i class="bi bi-journals"></i>&nbsp;{{languageService.getItem(477)}}</button>
        <button class="btn btn-primary" (click)="connect()" *ngIf="id>0&&(permissionService.permissions.Templates>1)"><i class="bi bi-link"></i>&nbsp;{{languageService.getItem(1379)}}</button>
        <swe-confirm [body]="languageService.getItem(1421)" (acceptChange)="delete()" *ngIf="id>0&&(permissionService.permissions.Templates>2)">
          <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
</div>
