<swe-card header="{{languageService.getItem(681)}}" [color]="'swe'" [(open)]="open" (refreshChange)="search(false)" (openChange)="search(false)">
  <span swe-hits *ngIf="open">&nbsp;({{hits}}<span *ngIf="more">+</span>)</span>
  <div class="btn-group ms-1 align-top" *ngIf="permissionService.permissions.ReplySms && messages.length>0 && type=='booking'">
    <div class="dropdown">
      <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe" (click)="listchecked=!listchecked;listutility.checkall(listchecked)">
        <span><i class="bi bi-check-lg"></i></span>
      </button>
      <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe" (click)="actionmenu=!actionmenu" [attr.disabled]="listutility.ischecked ? null : true">
        <span><i class="bi bi-swe-fw bi-three-dots-vertical"></i></span>
      </button>
      <ul class="dropdown-menu" [ngClass]="{'show': actionmenu && listutility.ischecked}">
        <li class="dropdown-item swe-click"><a (click)="resend()"><i class="bi bi-swe-fw bi-swe-bookingtype-replaceable"></i>&nbsp;{{languageService.getItem(768)}}</a></li>
      </ul>
    </div>
  </div>
  <swe-list [data]="messages" [loading]="loading">
    <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-info': message.checked}" *ngFor="let message of messages" (click)="goto(message.MessageId, message.Category)">
      <ng-container *ngIf="type=='booking'">
        <div class="col-3 col-md-1" (click)="listutility.toggle(message, $event)">
          <span class="bi-stack bi-swe-letter-circle-small" *ngIf="message.checked">
            <i class="bi bi-check-circle-fill bi-swe-stack-2x"></i>
          </span>
          <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="!message.checked">
            <i class="bi bi-circle-fill bi-swe-stack-2x"></i>
            <strong class="bi-swe-stack-1x bi-swe-letter">{{message.DisplayName[0]}}</strong>
          </span>
        </div>
        <div class="col-9 col-md-11 d-flex flex-wrap">
          <div class="col-12 col-md-1"><span *ngIf="message.MsgType==6">{{message.ReplyCode}}</span></div>
          <div class="col-12 col-md-2">
            {{dateTimeService.format(message.SendTime,'yyyy-MM-dd HH:mm:ss')}}
            <div *ngIf="message.SendDiff"><span class="small">({{dateTimeService.format(message.CreatedTime, 'yyyy-MM-dd HH:mm:ss')}})</span></div>
          </div>
          <div class="col-12 col-md-2">{{message.From}}</div>
          <div class="col-12 col-md-2">{{message.DisplayName}}</div>
          <div class="col-12 col-md-2">{{message.Status}}</div>
          <div class="col-12 col-md-3">
            <div *ngFor="let msg of message.Reply.split('|'); index as i" [ngClass]="{'alert-warning': i > 0}" [attr.title]="msg">{{msg.substr(0,10)}}<span *ngIf="msg.length>10">...</span></div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="type!='booking'">
        <div class="col-12 col-md-2">
          {{dateTimeService.format(message.SendTime,'yyyy-MM-dd HH:mm:ss')}}
          <div *ngIf="message.SendDiff"><span class="small">({{dateTimeService.format(message.CreatedTime, 'yyyy-MM-dd HH:mm:ss')}})</span></div>
        </div>
        <div class="col-12 col-md-2">{{message.From}}</div>
        <div class="col-12 col-md-2">{{message.DisplayName}}</div>
        <div class="col-12 col-md-3" [attr.title]="message.Body">
          <span class="font-weight-bold" *ngIf="message.Subject.length>0">{{message.Subject}} </span>
          <span>{{message.Body.substring(0,50)}}</span>
          <span *ngIf="message.Body.length>50">...</span>
        </div>
        <div class="col-12 col-md-1">{{message.Type}}</div>
        <div class="col-12 col-md-1">{{message.Status}}</div>
        <div class="col-12 col-md-1"><i class="bi bi-paperclip" *ngIf="message.Attachments.length>0"></i></div>
      </ng-container>
    </a>
    <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </swe-list>
</swe-card>
