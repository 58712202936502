import { Component, Input, OnInit, EventEmitter, Output, ViewChild, ElementRef, OnChanges } from '@angular/core';

import { AuthenticationService } from '../_services/authentication.service';
import { PermissionService } from '../_services/permission.service';
import { PropertyService } from '../_services/property.service';
import { DateTimeService } from '../_services/datetime.service';
import { ListService } from '../_services/list.service';
import { LanguageService } from '../_services/language.service';

@Component({
  selector: 'swe-element',
  templateUrl: './element.component.html',
})
export class ElementComponent implements OnInit, OnChanges {
  @Input() label: string;  //Required
  @Input() statusLabel: number = 0; //0 = Hide, 1 = Standard, 2 = Grouped, 3 = Inline, 4 = Custom
  @Input() labelToRight: boolean = false;
  @Input() hideLabel: boolean = false;
  @Input() customCols: number = 0;
  @Input() type: string;
  @Input() items: any[];
  @Input() format: string;
  @Input() optional: boolean;
  @Input() optionalValue: any = "";
  @Input() maxLength: number = 0;
  @Input() inheritance: boolean = false;
  @Input() inherit: boolean = false;
  @Output() inheritChange = new EventEmitter<any>();
  @Input() parentValue: any;
  @Input() model: any;
  @Input() formatvalue: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() changed: any;
  @Output() changedChange = new EventEmitter<any>();
  @Input() invalid: any;
  @Input() display: string;
  @Input() placeholder: string;
  @Input() propertyId: number;
  @Input() access: number = 3;
  @Input() disabled: boolean;
  @Input() markRequired: boolean;
  @Input() container: any = {};
  @Input() bottomMargin: number = 3;
  @Input() allownull: boolean = false;
  @Input() nullToEmpty: boolean = false;
  @Input() setFocus: boolean = false;
  @Input() enterKeyHint: string;
  @Input() hint: string;
  @Output() setFocusChange = new EventEmitter();
  @ViewChild('sweFocusElement') focusElement: ElementRef;
  @Output() enterChange = new EventEmitter();
  @Input() style: string = '';
  @Input() objectId: number = 0;
  @Input() profileList: any[] = []; //Add whole ProfileList if dependency is used
  @Input() hideCounter: boolean = false;
  
  @Output() validationMethod = new EventEmitter<any>();
  @Input() validation: ValidationObject;

  private original: any;
  private _isnull: boolean = false;
  private _styleobject: any;
  private _description: string = '';
  private _track: boolean = false;
  private _cssheight: string = '';
  private _linkEdit: number = 0; //0=Not in use, 1=Read, 2=Edit
  private _oldDisabled: boolean = false;
  private _showcounter: boolean = false;
  private _counter: number = 0;
  private _max: number = 600;

  constructor(
    private authenticationService: AuthenticationService,
    private permissionService: PermissionService,
    private propertyService: PropertyService,
    private dateTimeService: DateTimeService,
    private listService: ListService,
    private languageService: LanguageService
  ) {
    
  }

  ngOnInit() {
    this.requiredFields();
    if (this.propertyId) {
      let property: any = this.propertyService.getProperty(this.propertyId);
      if (property) {
        this._description = property.Description;
        this._track = property.Track;
        if (this.container && this.container.Type) {
          this.type = this.container.Type;
        }
        else {
          this.type = property.Type;
        }
        this.format = property.Format;
        this.items = property.Items;
        this.optional = property.IsOptional;
        this.maxLength = property.Length;
        if (this.maxLength > 0 && this.maxLength < this._max) {
          this._max = this.maxLength;
        }
        this.inheritance = property.Inheritance;

        if (property.Style && property.Style.length > 0) {
          this.style = property.Style;
        }

        this._oldDisabled = this.disabled;
        if (this.inherit) {
          this.disabled = true;
        }
      }
    }

    this.manageStyle();

    if (this.type == 'System.GroupByMultiList') {
      let min = 4;
      let max = 0;
      if (this.container && this.container.min) { min = this.container.min; }
      if (this.container && this.container.max) { max = this.container.max; }

      let height = 0;
      let padding = 10.5
      let noItems = this.items.length;
      this.items.forEach(groupby => {
        noItems += groupby.Items.length;
      });
      if (max > 0 && noItems > max) {
        height = max * 20;
      }
      else if (noItems < min) {
        height = min * 20;
      }
      else {
        height = noItems * 20;
      }

      this._cssheight = (height + padding) + "px";
    }
    else if (this.type == 'Internetplatsen.Document' && this.container.allowedPrefix) {
      this._description = this.languageService.getItem(800).replace('{0}', this.container.allowedPrefix);
    }
    else if (this.type == 'System.Link' || this.type == 'System.InternalLinkList') {
      this._linkEdit = 1;
    }
    else if (this.type == 'System.TextArea') {
      this._showcounter = true;
    }

    if (this.model != null && (this.type == "System.DateTime" || this.type == "System.Date" || this.type == "System.Time")) {
      this.original = this.dateTimeService.formatWithTimeZone(new Date(this.model));
    }
    else {
      this.original = this.model;
    }

    if (this.container && this.container.description && this.container.description.length > 0) {
      this._description = this.container.description;
    }

    if (this._showcounter && this.model) {
      this._counter = this.model.length;
    }
    else {
      this._counter = 0;
    }
  }

  ngOnChanges() {
    if (this.setFocus) {
      setTimeout(() => {
        if (this.focusElement) {
          let element = this.focusElement.nativeElement;
          element.focus();
          if (element.select) {
            element.select();
          }
        }
      }, 0); //Create a macrotask that will run in the next VM turn

      this.setFocus = false;
      this.setFocusChange.emit(this.setFocus);
    }
  }



  
  //Properties
  public get isValid() {
    return this.validation ? this.validation.isValid : true;
  }
  public get errorMessage() {
    return this.validation ? this.validation.errorMessage : '';
  }
  public get cssheight() {
    return this._cssheight;
  }
  public get description() {
    return this._description;
  }
  public get track() {
    return this._track;
  }
  public get styleobject() {
    return this._styleobject;
  }
  public get cols(): number {

    if (this.statusLabel == 1) {
      //Standard
      return this.authenticationService.isAuth ? this.permissionService.permissions.ProfileLabel : this.permissionService.unauthpermissions.ProfileLabel;
    }
    else if (this.statusLabel == 2) {
      //Grouped
      return 0;
    }
    else if (this.statusLabel == 3) {
      //Inline
      return 3;
    }
    else if (this.statusLabel == 4) {
      //Custom
      return this.customCols;
    }

    return 0;
  }
  public get isChanged() {
    return this.changed;
  }
  public get isNull() {
    return this._isnull;
  }
  public get linkEdit() {
    return this._linkEdit;
  }
  public set linkEdit(val) {
    this._linkEdit = val;
  }
  public get showinheritance() {
    return this.inheritance && this._linkEdit != 1;
  }
  public get inheritancetitle() {
    if (this.showinheritance) {
      if (!this.inherit) {
        return this.languageService.getItem(1302);
      }
      else {
        return this.languageService.getItem(1303);
      }
    }

    return '';
  }
  public get showcounter() {
    return this._showcounter;
  }
  public get counter() {
    return this._counter;
  }
  public get max() {
    return this._max;
  }




  /*Methods*/
  public isClickableLabel() {
    if (this.type == 'System.Boolean') {
      if ((this.statusLabel == 4 && this.customCols == 0) || this.labelToRight) {
        return true;
      }
    }

    return false;
  }
  public clickableLabel() {
    if (this.isClickableLabel()) {
      this.model = !this.model;
      this.manageChange();
    }
  }
  public getLabelClass() {

    let cls = '';

    if (this.cols > 0) {
      if (this.access > 1) {
        cls = 'col-form-label col-' + this.cols;
      }
      else {
        cls = 'py-1 col-' + this.cols;
      }

      if (this.labelToRight) {
        cls += ' order-last';
      }
    }
    else {
      if (this.access > 1) {
        cls = 'col-form-label';
      }
    }

    if (this.hint) {
      cls += ' d-block';
    }

    return cls;
  }
  public getInputClass() {
    let cls = '';

    if (this.cols > 0) {
      let remove = this.cols;
      if (this.inheritance && this._linkEdit != 1) {
        remove++;
      }

      cls = 'col-' + (12 - remove);

      if (this.labelToRight) {
        cls += ' order-first';
      }
    }
    else if (this.statusLabel == 4) {
      cls = 'float-start';
    }

    return cls;
  }
  public getExtraClass() {
    let cls = '';

    if (this.inheritance && this._linkEdit != 1) {
      cls = 'col-1';
    }

    return cls;
  }
  public toggleLink() {
    if (this._linkEdit == 2) {
      this._linkEdit = 1;
    }
    else {
      this._linkEdit = 2;
    }
  }
  public toggleInheritance() {
    if (this.access < 2) {
      return;
    }

    this.inherit = !this.inherit;
    this.inheritChange.emit(this.inherit);

    if (this.inherit) {
      this.disabled = true;
      this.model = this.parentValue;
      this.manageChange();
    }
    else {
      this.disabled = this._oldDisabled;
      this.model = null;
      this.manageChange();
    }
  }
  public manageChange() {
    if (this.validation) {
      this.validationMethod.emit(this.model);
    }

    if (this.type == 'System.Date' && this.model != null) {
      let date = new Date(this.model);
      this.model = this.dateTimeService.formatWithTimeZone(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0));
    }
    if (this.nullToEmpty && !this.model) {
      this.modelChange.emit();
    }
    else {
    this.modelChange.emit(this.model);
    }

    if (this.propertyId || (this.container && this.container.markChanges)) {
      //Only for profiles
      this.changed = (typeof this.original != 'undefined' && this.original != this.model) || (this.inheritance && this.inherit);
      this.changedChange.emit(this.changed);
    }

    
  }
  public manageBoolean() {
    if (!this.disabled) {
      if (this.model) {
        this.model = false;
      }
      else {
        this.model = true;
      }

      this.manageChange();
    }
  }
  public keydown(e, ignoreEnter: boolean = false) {

    if (e.key != 'Control') {
      this._isnull = false;
    }

    if (e.ctrlKey || e.metaKey) {
      if (e.key == '0') {
        this.model = null;
        this._isnull = true;

        this.modelChange.emit(this.model);
      }
      //else if (e.keyCode == 83) {
      //  //Ctrl+s
      //  this.enterChange.emit(this.model);
      //  e.preventDefault();
      //}
    }

    if (!ignoreEnter && e.keyCode == 13 && !e.shiftKey) {
      //Enter
      this.enterChange.emit(this.model);
      e.preventDefault();
    }
  }

  public keyup(e) {
    if (this._showcounter && this.model) {
      this._counter = this.model.length;
    }
    else {
      this._counter = 0;
    }
  }
  public getReadOnlyItems() {
    let list = [];

    if (this.model || this.model == 0) {
      if (Array.isArray(this.model)) {
        this.model.forEach((id) => {
          let item = this.listService.find(this.items, 'Id', parseInt(id));
          if (item) {
            list.push(item);
          }
        });
      }
      else if (this.container && this.container.isbit) {
        //for (let i = 1; i < this.model + 1; i *= 2) {
        //  if ((this.model & i) == i) {
        //    let item = this.listService.find(this.items, 'Id', i);
        //    if (item) {
        //      list.push(item);
        //    }
        //  }
        //}
        this.items.forEach((item) => {
          if ((this.model & item.Id) == item.Id) {
            list.push(item);
          }
        });
      }
      else {
        this.model.toString().split('|').forEach((id) => {
          let item = this.listService.find(this.items, 'Id', parseInt(id));
          if (item) {
            list.push(item);
          }
        });
      }
    }

    return list;
  }
  public getManagedItems() {

    if (this.propertyId) {

      let property: any = this.propertyService.getProperty(this.propertyId);
      if (property.ListDependencyId.length > 0 && this.profileList) {

        let dependencyProfile = this.listService.find(this.profileList, 'Property', parseInt(property.ListDependencyId));
        if (dependencyProfile && dependencyProfile.Value && dependencyProfile.Value.toString().length > 0) {

          let list = [];
          this.items.forEach((item) => {

            if (item.Dependency && item.Dependency.indexOf('|' + dependencyProfile.Value + '|') > -1) {
              list.push(item);
            }
          });

          return list;
        }
      }
    }

    return this.items;
  }


  

  //Functions
  private manageStyle() {
    if (this.style && this.style.length > 0) {
      this._styleobject = {};
      this.style.split(';').forEach((keyvalue) => {
        let parameters = keyvalue.split(':');
        this._styleobject[parameters[0]] = parameters[1];
        
      });
    }
  }
  private requiredFields() {
    if (this.label == null) {
      throw new Error("Attribute 'label' is required");
    }
  }

}

export interface ValidationObject {
  isValid: boolean;
  successMessage?: string;
  errorMessage?: string;
}
