<swe-card header="{{languageService.getItem(905)}}" [color]="'swe'" [(open)]="open" [hasCollapse]="true" [hasRefresh]="false" (openChange)="loademployments()" [rulelist]="['SweUserEmployments']">
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>{{languageService.getItem(906)}}</th>
          <th>{{languageService.getItem(907)}}</th>
          <th>{{languageService.getItem(1217)}}</th>
          <th>{{languageService.getItem(908)}}</th>
          <th>{{languageService.getItem(925)}}</th>
          <th *ngIf="employments && employments.length>0 && employments[0].EmployerFeeList.length>0">{{languageService.getItem(1271)}}</th>
          <th *ngIf="employments && employments.length>0 && employments[0].FactorAccess>0"><span [attr.title]="languageService.getItem(1383)">{{languageService.getItem(1383).substring(0,8)}}...</span></th>
          <th *ngIf="employments && employments.length>0 && employments[0].FactorAccess>0"><span [attr.title]="languageService.getItem(1384)">{{languageService.getItem(1384).substring(0,8)}}...</span></th>
          <th *ngIf="employments && employments.length>0 && employments[0].ExternalAccess>0">{{languageService.getItem(517)}}</th>
          <th *ngIf="employments && employments.length>0 && employments[0].ExternalAccess>0"></th>
          <th *ngIf="employments && employments.length>0 && employments[0].ExternalAccess>0"></th>
          <th *ngIf="employments && employments.length>0 && employments[0].FactorAccess>0"></th>
          <th *ngIf="employments && employments.length>0 && employments[0].FactorAccess>0"></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let employment of employments">
          <td style="width:140px;"><swe-element [type]="'System.Date'" [label]="languageService.getItem(906)" [statusLabel]="0" [(model)]="employment.Start"></swe-element></td>
          <td style="width:140px;"><swe-element [type]="'System.Date'" [label]="languageService.getItem(907)" [statusLabel]="0" [(model)]="employment.End"></swe-element></td>
          <td><swe-element [type]="'System.Double'" [label]="languageService.getItem(908)" [statusLabel]="0" [(model)]="employment.Price"></swe-element></td>
          <td><swe-element [type]="'System.Double'" [label]="languageService.getItem(908)" [statusLabel]="0" [(model)]="employment.Degree"></swe-element></td>
          <td><swe-element [type]="'System.List'" [label]="languageService.getItem(925)" [statusLabel]="0" [(model)]="employment.Type" [items]="employmenttypes"></swe-element></td>
          <td *ngIf="employment.EmployerFeeList&&employment.EmployerFeeList.length>0"><swe-element [type]="'System.List'" [label]="languageService.getItem(1271)" [statusLabel]="0" [(model)]="employment.EmployerFee" [items]="employment.EmployerFeeList"></swe-element></td>
          <td *ngIf="employment.FactorAccess>0"><swe-element [type]="'System.Double'" [label]="languageService.getItem(1383)" [statusLabel]="0" [(model)]="employment.OffDutyFactor" [access]="employment.FactorAccess"></swe-element></td>
          <td *ngIf="employment.FactorAccess>0"><swe-element [type]="'System.Double'" [label]="languageService.getItem(1384)" [statusLabel]="0" [(model)]="employment.VacationFactor" [access]="employment.FactorAccess"></swe-element></td>
          <td *ngIf="employment.ExternalAccess>0"><swe-element [type]="'System.List'" [label]="languageService.getItem(925)" [statusLabel]="0" [(model)]="employment.Category" [items]="employmentcategories" [optional]="true" [disabled]="employment.ExternalAccess<2"></swe-element></td>
          <td *ngIf="employment.ExternalAccess>0">{{employment.xCategory}}</td>
          <td *ngIf="employment.ExternalAccess>0">{{employment.xUnique}}</td>
          <td>
            <i *ngIf="employment.Id>0&&!isValid(employment)" class="bi-exclamation-circle swe-click bi bi-swe-2x text-warning" [attr.title]="languageService.getItem(1306)"></i>
          </td>
          <td>
            <button class="btn btn-primary" (click)="saveemployment(employment)" [disabled]="employment.Id>0&&!isValid(employment)" *ngIf="access>1"><i class="bi bi-floppy2-fill"></i></button>
          </td>
          <td>
            <button class="btn btn-success" (click)="createdigital(employment)" [disabled]="employment.Id>0&&!isValid(employment)" *ngIf="employment.Id>0&&access>1&&employment.DigitalAccess>1"><i class="bi bi-file-earmark-plus"></i></button>
          </td>
          <td>
            <button class="btn btn-danger" (click)="deleteemployment(employment.Id)" *ngIf="employment.Id>0&&access>2"><i class="bi bi-trash-fill"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="height:250px;">
      &nbsp;
    </div>
  </div>
</swe-card>
