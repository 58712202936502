<div class="row">
  <div class="col-12 col-xl-5 float-start">
    <swe-card [(open)]="open" [hasCollapse]="false" [header]="languageService.getItem(1393)" [hasRefresh]="true" (refreshChange)="load()" [hasEditMode]="id>0&&permissionService.permissions.GuideAccess>1">
      <span swe-rightcommand><i [ngClass]="{'bi-lock-fill': locked, 'bi-unlock-fill': !locked}" class="bi bi-swe-pull-right swe-click" (click)="lock=!lock" *ngIf="systemAdmin&&id!=0"></i></span>
      <ng-container *ngIf="!isLoading && guide && permissionService.permissions.GuideAccess > 0">
        <swe-element type="System.String" [statusLabel]="editmode ? 2 : 1" [label]="languageService.getItem(130)" [(model)]="guide.Name" [access]="editmode?3:1" [disabled]="locked"></swe-element>
        <swe-element type="System.List" [statusLabel]="editmode ? 2 : 1" [label]="languageService.getItem(1391)" [items]="languages" [(model)]="guide.Culture" [access]="editmode?3:1" [disabled]="locked"></swe-element>
        <swe-element type="System.MultiList" [container]="{isbit: true, max:6}" [statusLabel]="editmode ? 2 : 1" [label]="languageService.getItem(1)" [(model)]="guide.Keywords" [items]="generalService.guideKeywords" [access]="editmode?3:1" [disabled]="locked"></swe-element>
        <!--Access-->
        <swe-card [open]="locked" [header]="languageService.getItem(142)" [hasRefresh]="false">
          <swe-element type="System.Boolean" [statusLabel]="editmode?2:1" [label]="languageService.getItem(343)" [(model)]="guide.IsActive" [access]="editmode?3:1"></swe-element>
          <swe-element type="System.MultiList" [statusLabel]="editmode ? 2 : 1" [label]="languageService.getItem(82)" [(model)]="guide.Roles" [items]="generalService.roles" [access]="editmode?3:1"></swe-element>
          <div class="row" *ngIf="permissionService.user.IsSuper">
            <ng-container *ngFor="let item of selectedRules; index as i;">
              <div class="col-6 col-xxl-8">
                <swe-element type="System.List" [access]="editmode?3:1" [label]="languageService.getItem(607)" [statusLabel]="editmode ? 2 : 0" [(model)]="item.RuleId" [items]="ruleKeywords"></swe-element>
              </div>
              <div class="col-5 col-xxl-2">
                <swe-element type="System.List" [access]="editmode?3:1" [label]="languageService.getItem(142)" [statusLabel]="editmode ? 2 : 0" [(model)]="item.Access" [items]="access"></swe-element>
              </div>
              <div class="col-1  my-auto" *ngIf="editmode">
                <a class="swe-no-link me-2" href="javascript:void(0);" [attr.title]="languageService.getItem(17)" (click)="removeRule(i)" ><i class="bi bi-swe-fw bi-dash-circle-fill"></i></a>
                <a class="swe-no-link" href="javascript:void(0);" [attr.title]="languageService.getItem(100)" (click)="addRule()" *ngIf="i == selectedRules.length - 1"><i class="bi bi-swe-fw bi-plus-circle-fill"></i></a>
              </div>
            </ng-container>
            <button *ngIf="locked" class="btn btn-primary" (click)="saveAccess()"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}} {{languageService.getItem(142).toLowerCase()}}</button>
          </div>
        </swe-card>

        <div class="d-grid gap-2">
          <button *ngIf="permissionService.permissions.GuideAccess > 1" [disabled]="locked" class="btn btn-primary" (click)="save()"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
          <swe-confirm *ngIf="id>0&&permissionService.permissions.GuideAccess > 2&&!systemAdmin" [body]="languageService.getItem(1394)" (acceptChange)="delete()">
            <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
          </swe-confirm>
        </div>
      </ng-container>
    </swe-card>
  </div>
  <div class="col-12 col-xl-5 float-start" *ngIf="!isLoading && guide && permissionService.permissions.GuideAccess > 0">
    <!--Content-->
    <swe-card [hasRefresh]="false" [hasCollapse]="false" [open]="true" [header]="languageService.getItem(1399)">
      <span swe-rightcommand><i class="bi bi-plus-lg bi-swe-pull-right swe-click" (click)="addContent($event)" *ngIf="editmode&&!locked"></i></span>
      <span swe-rightcommand><i class="bi bi-arrows-collapse bi-swe-pull-right swe-click" (click)="collapseAllContents()" *ngIf="guide.Contents && guide.Contents.length > 1"></i></span>
      <swe-card *ngFor="let content of guide.Contents; index as i;" [rememberopen]="true" [id]="content.SortOrder" [hasRefresh]="false" [header]="languageService.getItem(1410) + ' ' + content.SortOrder" [(open)]="content.IsOpen">
        <span swe-rightcommand *ngIf="!locked&&editmode && guide.Contents.length > 1"><i class="bi bi-trash-fill bi-swe-pull-right swe-click" (click)="removeContent(i)" *ngIf="editmode"></i></span>
        <swe-element type="System.Int32" [access]="editmode?3:1" [label]="languageService.getItem(490)" [statusLabel]="editmode ? 2 : 1" [(model)]="content.SortOrder" [disabled]="locked"> </swe-element>
        <swe-richtexteditor [(model)]="content.Body" [label]="languageService.getItem(1399)" [statusLabel]="editmode ? 2:0" [disabled]="locked || !editmode"></swe-richtexteditor>
      </swe-card>
    </swe-card>
  </div>
</div>
