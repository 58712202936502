import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

import { LanguageService } from '../_services/language.service';
import { ListService } from '../_services/list.service';
import { PropertyService } from '../_services/property.service';
import { DateTimeService } from '../_services/datetime.service';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'swe-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {

  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() statusLabel: number = 1;
  @Input() id: number = 0;
  @Input() tabgroupid: number = 0;
  @Input() rulelist: string[];
  @Input() hasEditMode: boolean = false;
  @Input() editMode: boolean = false;
  @Input() relatedObjectList: relatedObject[];

  private _groupedby = {};
  private _showIsNotOptionalText: boolean = false;
  

  constructor(
    public languageService: LanguageService,
    private listService: ListService,
    private propertyService: PropertyService,
    private dateTimeService: DateTimeService
  ) {
   
  }


  ngOnInit() {
    if (this.model) {

      //Container
      this.model.forEach((profile) => {

        profile.Container = {};

        let property = this.propertyService.getProperty(profile.Property);
        if (property) {
          if (!property.IsOptional) {
            this._showIsNotOptionalText = true;
          }

          if (property.Type == 'Internetplatsen.Document') {
            if (profile.Value) {
              let doc = profile.Value.split('|');
              if (doc) {
                profile.Container['document'] = doc.length > 1 ? doc[1] : doc[0];
              }
            }
            profile.Container['folder'] = 'ProfileDocuments';
            profile.Container['allowedPrefix'] = 'png, jpg, jfif, jpeg, gif, txt, csv, pdf, doc, docx, ppt, pptx, xls, xlsx, eps, tif, odt, ods, odp';
          }
          else if (property.Type == 'System.String') {
            if (profile.Value) {
              if (profile.Value.indexOf('http://') > -1 || profile.Value.indexOf('https://') > -1) {
                let linkarray = profile.Value.split('|');

                profile.Container['Type'] = 'System.Link';
                profile.Container['Link'] = linkarray[0];
                profile.Container['Url'] = (linkarray.length == 2) ? linkarray[1] : linkarray[0];
              }
            }
          }
          else if (property.Type == 'System.Email' && profile.Value && profile.Value.length > 0) {
            profile.Container['Type'] = 'System.Link';
            profile.Container['Link'] = profile.Value;
            profile.Container['Url'] = 'mailto:' + profile.Value;
          }
          else if (property.Type == 'System.Cellphone' && profile.Value && profile.Value.length > 0) {
            profile.Container['Type'] = 'System.Link';
            profile.Container['Link'] = profile.Value;
            profile.Container['Url'] = 'tel:' + profile.Value;
          }
        }
      });

      //Distinct
      let flags = {};
      let tabId = this.tabgroupid;
      let distinct = this.model.filter(function (entry) {
        if (tabId != 0 && entry.TabGroupBy != tabId) {
          return false;
        }
        if (flags[entry.GroupSort]) {
          return false;
        }
        flags[entry.GroupSort] = true;
        return true;
      });

      let grouplist = distinct.map(function (group) { return { Id: group.GroupBy, Name: group.GroupName, Sort: group.GroupSort, IsOpen: group.GroupIsOpen }; });
      this._groupedby = this.listService.groupByInt(this.model, 'GroupBy', grouplist);
      grouplist.forEach(x => {
        this._groupedby[x.Sort].IsOpen = x.IsOpen;
      });
    }
  }


/*Properties*/
  public get groupedby() {
    return this._groupedby;
  }
  public get showIsNotOptionalText() {
    return this._showIsNotOptionalText;
  }


  //Comparer
  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }


  //Methods
  public done() {
    this.modelChange.emit(this.model);
  }
  public visibledependency(profile) {
    
    let res = false;

    let propertyId = profile.Property;

    let property = this.propertyService.getProperty(propertyId);

    if (property.VisibleId.length > 0) {

      let idList = property.VisibleId.split('|');
      for (let i = 0; i < idList.length; i++) {
        let id = idList[i];
        let dependency = this.getProfile(id);
        if (dependency == null) {
          dependency = this.getRelatedObject(id);
        }

        if (dependency != null && dependency.Value == null) {
          //Profile missing
          let dependencyProperty = this.propertyService.getProperty(id);
          if (dependencyProperty != null) {
            //Get property by type to set default values
            if (dependencyProperty.Type == 'System.Boolean') {
              //dependency.Value = '';
            }
          }
        }

        if (dependency != null && dependency.Value != null) {
          //Multiple selectbox
          let dependencyArr = dependency.Value.toString().split('|');
          for (let j = 0; j < dependencyArr.length; j++) {
            if (typeof property.VisibleVal != "undefined") {
              //Multiple dependencies
              let valueArr = property.VisibleVal.toString().split('|');
              for (let k = 0; k < valueArr.length; k++) {
                if (valueArr[k].toLowerCase() == dependencyArr[j].toLocaleLowerCase()) {
                  res = true;
                  break;
                }
              }
            }
          }
        }

        if (res) {
          //One statement is enough
          break;
        }
      }
    }
    else {
      res = true;
    }

    profile.IsVisible = res;
    if (!profile.IsVisible) {
      profile.Changed = false;
    }

    return res;
  }
  public valuedependency(e, propertyId) {
    
    let targetProperty = this.propertyService.getPropertyValueDependency(propertyId);

    if (targetProperty.DependencyId) {

      let source = this.getProfile(propertyId);
      let target = this.getProfile(targetProperty.Id);

      if (typeof e != "undefined") {

        if (targetProperty.Type == "System.Int32") {
          target.Value = parseInt(source.Value) + parseInt(targetProperty.DependencyVal);
        }
        else if (targetProperty.Type == "System.Double") {
          target.Value = parseFloat(source.Value) + parseFloat(targetProperty.DependencyVal);
        }
        else if (targetProperty.Type == 'System.DateTime') {
          var dtSource = new Date(source.Value);

          var minutes = parseInt(targetProperty.DependencyVal);

          dtSource.setTime(dtSource.getTime() + (minutes * 60 * 1000));

          target.Value = this.dateTimeService.formatWithTimeZone(dtSource);
        }
        else if (targetProperty.Type == "System.String") {
          target.Value = source.Value + targetProperty.DependencyVal;
        }
        else if (targetProperty.Type == "System.Boolean") {
          target.Value = source.Value
        }


      }

    }
  }




  //Function
  private getProfile(id: number):any {

    let profile: any;

    if (this.model) {
      profile = this.listService.find(this.model, 'Property', id);
      
    }

    return profile;
  }
  private getRelatedObject(key: string): any {

    let profile: any;

    if (this.model) {
      profile = this.listService.find(this.relatedObjectList, 'Name', key);

    }

    return profile;
  }
}

export class relatedObject {
  public Name: string;
  public Value: any;
}
